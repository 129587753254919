import React, { useState } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ChevronThinLeft, ChevronThinRight } from 'styled-icons/entypo/';

const Wrapper = styled.div`
    width: 100%;
    background: ${props => props.theme.colors.gray};
`
const ImageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
const Img = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
`
const PrevNext = styled.div`
    @media (max-width: 800px) {
        display: none;
    }
`
const PrevBtn = styled.div`
    width: 150px;
    height: 150px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background: ${props => props.theme.colors.darkGray};
    }
`

const Left = styled(ChevronThinLeft)`
    color: #fff;
    cursor: pointer;
    &:hover {
        color: ${props => props.theme.colors.accent};
    }
`
const Right = styled(ChevronThinRight)`
    color: #fff;
    cursor: pointer;
    &:hover {
        color: ${props => props.theme.colors.accent};
    }
`
const Footer = styled.div`
    width: 100%;
    background: ${props => props.theme.colors.gray};
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const ShowIndex = styled.p`
    background: ${props => props.theme.colors.darkGray};
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const ShowIndexText = styled.p`
    font-family: ${props => props.theme.fonts.heading};
    font-weight: 600;
    color: ${props => props.theme.colors.white};
    font-size: 2em;
    padding: 10px 20px;
    @media (max-width: 520px) {
        font-size: 1em;
        padding: 10px 5px;
    }
`

const Title = styled.p`
    font-family: ${props => props.theme.fonts.heading};
    font-weight: 600;
    font-size: 1.2em;
    color: ${props => props.theme.colors.white};
    padding: 20px 10px 10px 10px;
`

const Index = ({ images }) => {

    // Images consists of an array with bild (GatsbyImage) and alt === Title, Alt-Title
    const [imageIndex, setImageIndex] = useState(0);

    const setPrevNext = (prevNext) => {

        let newIndex = 0;

        if (prevNext === 'next') {
            if (imageIndex === (images.length - 1)) {
                newIndex = 0;
            } else {
                newIndex = imageIndex + 1;
            }
        } else {
            if (imageIndex === 0) {
                newIndex = images.length - 1;
            } else {
                newIndex = imageIndex - 1;
            }
        }

        setImageIndex(newIndex);
    } 

    return (
        <Wrapper>
            <ImageWrapper>
                <PrevNext>
                    <PrevBtn onClick={() => setPrevNext('prev')}>
                        <Left size={50} />
                    </PrevBtn>
                </PrevNext>
                <Img>
                    <GatsbyImage image={getImage(images[imageIndex].bild)} alt={images[imageIndex].alt} />
                </Img>
                <PrevNext>
                    <PrevBtn onClick={() => setPrevNext('next')}>
                        <Right size={50} />
                    </PrevBtn>
                </PrevNext>
            </ImageWrapper>
            <Footer>
                <Title>{ images[imageIndex].alt }</Title>
                <ShowIndex>
                    <Left size={25} onClick={() => setPrevNext('prev')} />
                    <ShowIndexText>{ imageIndex + 1 } / { images.length }</ShowIndexText>
                    <Right size={25} onClick={() => setPrevNext('next')} />
                </ShowIndex>
            </Footer>
        </Wrapper>
    )
};

export default Index;
