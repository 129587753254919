import React from 'react';
import styled from 'styled-components';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3';
import { Time as _Time } from 'styled-icons/boxicons-regular/';
import { Attach } from 'styled-icons/evaicons-solid';
import SiteWrapper from '../siteWrapper';
import Content from '../content';
import Close from './close';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Neuigkeiten from '../news';
import Gallery from '../gallery';

const Wrapper = styled.div`
    max-width: 1100px;
    margin: auto;
`;
const ImageWrapper = styled.div`
    width: 100%;
`;
const Header = styled.div`
    padding: 20px;
    font-family: ${(props) => props.theme.fonts.heading};
`;
const Heading = styled.h1`
    font-size: 2.5em;
    margin: 0;
    padding-bottom: 0;
`;
const Time = styled.div`
    margin-top: 10px;
    font-size: 1.2em;
`;
const STime = styled(_Time)`
    color: ${(props) => props.theme.colors.gray2};
`;

const AnhangWrapper = styled.div`
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const Anhang = styled.a`
    color: ${(props) => props.theme.colors.darkGray};
    border: solid 1px ${(props) => props.theme.colors.darkGray};
    padding: 20px;
    text-align: center;
`;
const SAttach = styled(Attach)`
    color: ${({ theme }) => theme.colors.accent};
`;

const NewsTemplate = (props) => {
    const {
        time,
        title,
        html,
        beitragsbild,
        excerpt,
        galleryImages,
        anhang,
    } = props.pageContext;

    const renderAnhang = () => {
        if (!anhang || anhang?.fileAnhang === null) {
            return null;
        }

        return (
            <AnhangWrapper>
                <Anhang href={`${anhang.fileAnhang.publicURL}`} target="_blank">
                    <SAttach size={70} />
                    <div>{anhang.titleAnhang}</div>
                </Anhang>
            </AnhangWrapper>
        );
    };

    return (
        <ModalRoutingContext.Consumer>
            {({ modal, closeTo }) => (
                <SiteWrapper
                    modal={modal}
                    title={title}
                    article={true}
                    description={excerpt}
                >
                    {modal && <Close to={closeTo} />}
                    <Wrapper>
                        <Header>
                            <Heading className="animate__animated animate__fadeInLeft">
                                {title}
                            </Heading>
                            {time && (
                                <Time className="animate__animated animate__fadeInLeft">
                                    <STime size={24} /> {time}
                                </Time>
                            )}
                        </Header>
                        <ImageWrapper className="animate__animated animate__fadeInUp">
                            <GatsbyImage
                                image={getImage(beitragsbild)}
                                alt={title}
                            />
                        </ImageWrapper>
                        <Content className="animate__animated animate__fadeInUp">
                            {html}
                        </Content>
                        {galleryImages && galleryImages.length !== 0 && (
                            <Gallery images={galleryImages} />
                        )}
                        {renderAnhang()}
                    </Wrapper>
                    {!modal && <Neuigkeiten />}
                </SiteWrapper>
            )}
        </ModalRoutingContext.Consumer>
    );
};

export default NewsTemplate;
