import React from 'react';
import styled from 'styled-components';
import { Close as _Close } from 'styled-icons/material/';
import { Link } from 'gatsby';

const Wrapper = styled(Link)`
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 9999;
    @media (max-width: 980px) {
        bottom: 20px;
        right: 20px;
        top: auto;
    }
`
const SIcon = styled(_Close)`
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.accent};
    width: 70px;
    height: 70px;
    @media (max-width: 980px) {
        width: 50px;
        height: 50px;
    }
`

const CloseComp = ({ to }) => {
    return (
        <Wrapper to={to} className="animate__animated animate__fadeInRight animate__delay-1s">
            <SIcon />
        </Wrapper>
    )
};

export default CloseComp;
