import React from 'react';
import styled from 'styled-components';
import Slider from "react-slick";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Wrapper = styled.section`
    padding: 40px 0;
    width: 100%;
    max-width: 1000px;
    margin: auto;
    box-sizing: border-box;
    @media (max-width: 1170px) {
        max-width: 800px;
    }
    @media (max-width: 1030px) {
        max-width: 700px;
    }
    @media (max-width: 750px) {
        max-width: 500px;
    }
    @media (max-width: 530px) {
        max-width: 300px;
    }
    @media (max-width: 320px) {
        display: none;
    }
`
const SponsorWrap = styled.div`
    text-align: center;
`
const Title = styled.h1`
    font-size: 1.2em;
`

const Carousel = () => {

    const data = useStaticQuery(graphql`
        query SponsorenCarouselQuery {
            allFile(filter: {sourceInstanceName: {eq: "sites"}, name: {eq: "partner"}}) {
            edges {
                node {
                childMarkdownRemark {
                    frontmatter {
                        title
                        partnerList {
                        bild {
                            childImageSharp {
                                gatsbyImageData(width: 180)
                            }
                        }
                        partnerType
                        title
                        website
                        }
                    }
                }
                }
            }
            }
        }
    `)

    const sponsoren = data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 750,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
        },
        {
            breakpoint: 530,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            }
        }
        ]
    };

    const renderPartners = () => {
        const partnerArr = [];
        sponsoren.partnerList.forEach((sponsor) => {
            partnerArr.push(
                <SponsorWrap key={sponsor.title}>
                    <GatsbyImage image={getImage(sponsor.bild)} alt={sponsor.title} />
                </SponsorWrap>
            );
        })
        return partnerArr;
    }

    return (
        <Wrapper>
            <Title>Danke an unsere Sponsoren:</Title>
            <Slider {...settings}>
                {renderPartners()}
            </Slider>
        </Wrapper>
    )
};

export default Carousel;