import React from 'react';
import styled from 'styled-components';
import showdown from 'showdown';

const Wrapper = styled.section`
    max-width: 800px;
    margin: auto;
    padding: 20px;
    font-size: 1.2em;
    width: 100%;
    overflow: hidden;

    ${(props) =>
        props.fullWidth &&
        `
        max-width: 100%;
    `}

    strong {
        font-weight: 700;
        font-family: ${({ theme }) => theme.fonts.heading};
        font-size: 1.1em;
    }

    p {
        line-height: 1.7em;
    }

    img {
        margin: 30px 0;
        max-width: 100%;
    }

    ul {
        margin: 20px 0;
        list-style: circle;
    }

    li {
        padding: 5px 0;
    }

    h3 {
        font-size: 1.3em;
    }
`;

const Content = ({ children, toMarkdown, fullWidth }) => {
    const converter = new showdown.Converter();
    let content = children;

    if (toMarkdown) {
        content = converter.makeHtml(content);
    }

    return (
        <Wrapper
            fullWidth={fullWidth}
            dangerouslySetInnerHTML={{ __html: content }}
        />
    );
};

export default Content;
